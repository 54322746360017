<template>
	<w-layout v-if="task && task.status" align-center justify-center shrink>
		<v-tooltip top>
			<template v-slot:activator="{ on }">
				<v-icon class="ma-auto" :color="task.status.color" v-on="on">{{ taskIcon }}</v-icon>
			</template>
			<span v-t="task.status.name" />
		</v-tooltip>
	</w-layout>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskIcon',
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			taskIcon: null
		}
	},
	created: function () {
		if (this.service.isOfTaskInformationRequestType(this.task)) {
			this.taskIcon = 'info'
		} else if (this.service.isOfTaskAccountingFirmType(this.task)) {
			this.taskIcon = 'label'
		} else if (this.service.isOfTaskServiceRequestType(this.task)) {
			this.taskIcon = 'room_service'
		}
		if ((this.task.isChild || this.task.parent) && !this.service.isOfTaskServiceRequestType(this.task)) {
			this.taskIcon += '_outline'
		}
	}
}
</script>
